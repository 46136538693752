import React, { Component } from 'react';
import { Section } from '@/components/UI/Section';
import styles from './styles.module.scss';

class NotFound extends Component {
  render() {
    return (
      <Section className={styles.root}>
        <div className={'H1'}>Страница не найдена</div>
      </Section>
    );
  }
}

export default NotFound;
