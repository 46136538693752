import React from 'react';

import NotFound from '../components/NotFound';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <>
    <SEO title="Страница не найдена" />
    <NotFound />
  </>
);

export default NotFoundPage;
